<template>
  <div>
    <div class="frame-main__header__content">
      <div class="frame-main__header__content__title">
        <router-link :to="{ path: '/' }">
          <h1>{{ $t("html.labels.webpageTitle") }}</h1>
        </router-link>
      </div>
    </div>
    <div class="frame-main__header__buttons">
      <ThemeSelector />
      <LanguageSelector />
      <LogoutButton v-if="$user.isLoggedIn()" />
    </div>

    <div v-if="$user.isLoggedIn()" class="user-info">
      <div class="user-info-item">
        {{ $t("pages.login.loggedinuser") }}: {{ $user.resource.data.name }}
      </div>
      <div class="user-info-item">
        {{ $t("pages.login.Email") }}:
        {{ $user.resource.data.email }}
      </div>
      <div class="user-info-item">
        {{ $t("pages.login.UserID") }}:
        {{ $user.resource.data.id }}
      </div>
      <br />
      <div class="user-info-item">
        {{ $t("pages.login.SZTSZ") }}: {{ $user.resource.data.sztsz || "nem ismert" }}
      </div>
      <br />
      <div class="user-info-item">
        {{ $t("pages.login.Organization") }}:
        {{ $user.resource.data.fo_szervezeti_egyseg || "nem ismert" }}
      </div>
      <br />
      <div class="user-info-item">
        {{ $t("pages.login.TaxNumber") }}:
        {{ $user.resource.data.adoszam || "nem ismert" }}
      </div>
      <br />
      <div class="user-info-item employment-status">
        {{ $t("pages.login.EmploymentStatus") }}:
        {{ $user.resource.data.fogl_stat || "nem ismert" }}
      </div>
      <br />
      <div class="user-info-item">
        {{ $t("pages.login.JobTitle") }}:
        {{ $user.resource.data.munkakor || "nem ismert" }}
      </div>
      <!--<div class="user-info-item">
        {{ $t("pages.login.LeaveBalance") }}: Jelenleg fejlesztés alatt
      </div>-->
      <div class="user-info-item">
        {{ $t("pages.login.DailyWorkHours") }}:
        {{
          $user.resource.data.daily_work_hours
            ? $user.resource.data.daily_work_hours + " óra"
            : "Jelenleg fejlesztés alatt"
        }}
      </div>

      <div class="user-info-item">
        {{ $t("pages.login.WeeklyWorkHours") }}:
        {{
          $user.resource.data.weekly_work_hours
            ? $user.resource.data.weekly_work_hours + " óra"
            : "Jelenleg fejlesztés alatt"
        }}
      </div>

      <!-- 
      <div class="user-info-item">
        {{ $t("pages.login.MonthlyWorkHours") }}:
        {{ $user.resource.data.monthly_work_hours ?? "Jelenleg fejlesztés alatt" }}
      </div>

      <div class="user-info-item">
        {{ $t("pages.login.YearlyWorkHours") }}:
        {{ $user.resource.data.yearly_work_hours ?? "Jelenleg fejlesztés alatt" }}
      </div>

      <div class="user-info-item">
        {{ $t("pages.login.WeeklyWorkDays") }}:
        {{ $user.resource.data.weekly_work_days ?? "Jelenleg fejlesztés alatt" }}
      </div>
      -->

      <br />

      <div v-if="roles.length > 0" class="roles-wrapper">
        <div
          v-for="role in roles"
          :key="role.id"
          :class="{
            'user-info-item': true,
            'roles-container': roles.length === 1 || parseInt(sessionRole) === role.id,
          }"
        >
          <span> {{ $t("pages.login.Role") }}: </span>
          <a href="#" :onClick="setRole" :data-id="role.id">
            {{ getRoleTranslation(role.name) }}
          </a>
          <span v-if="role.sztsz" class="sztsz-container"> sztsz :{{ role.sztsz }} </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ThemeSelector from "../components/ThemeSelector";
import LogoutButton from "../components/buttons/LogoutButton";
import LanguageSelector from "../components/LanguageSelector";

export default {
  data() {
    return {
      translationMap: {
        "System.Administrator": "Rendszergazda",
        "DecisionMaker.Superior": "Másodlagos munkáltatói jogkör gyakorló",
        "DecisionMaker.SZTOK": "Elsődleges munkáltatói jogkör gyakorló",
        "DecisionMaker.EEGFI": "Döntéshozó EEGFI",
        "DecisionMaker.Principal": "Döntéshozó Elnök",
        User: "Felhasználó",
      },
      roles: [],
      sessionRole: null,
    };
  },
  name: "Header",

  components: {
    ThemeSelector,
    LogoutButton,
    LanguageSelector,
  },
  mounted() {
    if (this.$user.isLoggedIn()) {
      console.log("🔄 Bejelentkezett felhasználó és adatok betöltése...");

      fetch("/api/v1/rest/selected-role")
        .then((res) => res.text())
        .then((res) => {
          this.sessionRole = parseInt(res);
          console.log("✅ Lekért szerepkör ID:", this.sessionRole);
        })
        .finally(() => {
          const rolesData = this.$user.resource.data.roles;
          console.log("🔹 Elérhető szerepkörök az aktuális felhasználónak:", rolesData);

          if (rolesData) {
            this.roles = Object.values(rolesData).map((role) => role.data);

            if (!this.sessionRole && this.roles.length > 0) {
              this.sessionRole = this.roles[0].id;
            }
          }

          console.log("🟢 Aktuális felhasználói adatok:", this.$user.resource.data);
        });
    }
  },

  methods: {
    getRoleTranslation(roleName) {
      return this.translationMap[roleName] || roleName;
    },

    setRole($event) {
      window.location = "/setrole?id=" + $event.target.dataset.id;
    },
  },
};
</script>
<style scoped>
.user-info {
  background-color: #ffffff;
  border: 1px solid #ddd;
  border-radius: 6px;
  padding: 1rem;
  display: flex;
  flex-wrap: wrap;
  gap: 0.3rem;
  justify-content: start;
  width: 100%;
  margin: 20px auto;
}

.user-info-item {
  box-sizing: border-box;
  font-size: 0.875rem;
  color: #555;
  border-bottom-style: double;
  margin-left: 15px;
  display: flex;
  padding-bottom: 7px;
  padding-top: 7px;
}

.employment-status {
  color: green;
  font-weight: bold;
  text-decoration: none;
  border-bottom: 2px solid green;
  padding-bottom: 2px;
}

.roles-container {
  color: #c71585;
  font-weight: bold;
  text-decoration: none;
  border-bottom: 2px solid #c71585;
  padding-bottom: 2px;
  gap: 5px;
}

.roles-wrapper {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.roles-wrapper span {
  margin-right: 5px;
}

.sztsz-container {
  margin-left: 5px;
  position: relative;
  top: -3px;
  margin-bottom: 3px;
  font-size: 12px;
  font-weight: bold;
}
</style>
