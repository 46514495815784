import { RedirectRoute, Route } from "../classes/RouteTypes";
export default [
    // Index
    new RedirectRoute({ path: '/', redirect: { name: 'dashboard' }, name: 'index', hidden: true }),

    new Route({
        path: '/dashboard', name: 'dashboard',
        component: () => import('../pages/dashboard/Frame')
    }),

    new Route({
        path: '/users', name: 'users',
        component: () => import('../pages/users/Frame'),
        permissions: ['permission.grant'],
        children: [
            new RedirectRoute({ path: '', redirect: { name: 'users.list' }, name: 'users', hidden: true }),

            new Route({
                path: 'list', name: 'users.list', label: 'pages.users.breadcrumb.list',
                component: () => import('../pages/users/List')
            }),

            new Route({
                path: 'create', name: 'users.create', label: 'pages.users.breadcrumb.create',
                component: () => import('../pages/users/Create')
            }),

            new Route({
                path: ':user/edit', name: 'users.edit', label: 'pages.users.breadcrumb.edit',
                component: () => import('../pages/users/Edit')
            }),
        ]
    }),

    new Route({
        path: '/excel-imports',
        label: 'pages.excelimports.breadcrumb.frame',
        component: () => import('../pages/excel-imports/Frame'),
        permissions: ['permission.excel'],
        children: [
            // Redirect to the default child
            new RedirectRoute({ path: '', redirect: { name: 'excel-imports.overlay-rendering' }, name: 'excel-imports', hidden: true }),

            new Route({
                path: 'overlay-rendering',
                name: 'excel-imports.overlay-rendering',
                label: 'pages.excelimports.breadcrumb.overlayRendering',
                permissions: ['permission.excel'],
                component: () => import('../pages/excel-imports/OverlayRendering.vue')
            }),

            new Route({
                path: 'form-loading',
                name: 'excel-imports.form-loading',
                label: 'pages.excelimports.breadcrumb.formLoading',
                permissions: ['permission.excel'],
                component: () => import('../pages/excel-imports/FormLoading.vue')
            }),

            new Route({
                path: 'seeder-history',
                name: 'excel-imports.seeder-history',
                label: 'pages.excelimports.breadcrumb.history',
                permissions: ['permission.excel'],
                component: () => import('../pages/excel-imports/SeederHistory.vue')
            }),

            new Route({
                path: 'nightly_updates',
                name: 'excel-imports.nightly_updates',
                label: 'pages.excelimports.breadcrumb.nightly_updates',
                permissions: ['permission.excel'],
                component: () => import('../pages/excel-imports/NightlyUpdates.vue')
            }),

            new Route({
                path: 'excel_after_import',
                name: 'excel-imports.excel_after_import',
                label: 'pages.excelimports.breadcrumb.excel_after_import',
                permissions: ['permission.excel'],
                component: () => import('../pages/excel-imports/ExcelAfterImport.vue')
            }),

        ]
    }),

    new Route({
        path: '/permissions', label: 'pages.permissions.breadcrumb.frame',
        component: () => import('../pages/permissions/Frame'),
        permissions: ['permission.grant'],
        children: [
            // Redirect to the default children
            new RedirectRoute({ path: '', redirect: { name: 'permissions.assign' }, name: 'permissions', hidden: true }),

            new Route({
                path: 'edit', name: 'permissions.edit', label: 'pages.permissions.breadcrumb.edit',
                permissions: ['permission.edit'],
                component: () => import('../pages/permissions/Edit')
            }),

            new Route({
                path: 'assign', name: 'permissions.assign', label: 'pages.permissions.breadcrumb.assign',
                permissions: ['permission.grant'],
                component: () => import('../pages/permissions/Assign')
            }),
        ]
    }),

    new Route({
        path: '/application-forms',
        component: () => import('../pages/applicationForms/Frame'),
        children: [
            new RedirectRoute({ path: '', redirect: { name: 'applicationForms.list' } }),

            new Route({
                path: 'list', name: 'applicationForms.list', label: 'pages.applicationForm.breadcrumb.list',
                component: () => import('../pages/applicationForms/List')
            }),

            new Route({
                path: 'create', name: 'applicationForms.create',
                label: 'pages.applicationForm.breadcrumb.create',
                component: () => import('../pages/applicationForms/Create')
            }),

            new Route({
                path: ':form',
                component: () => import('../pages/applicationForms/form/Frame'),
                children: [
                    new Route({
                        path: '', label: 'pages.applicationForm.breadcrumb.view', name: 'applicationForms.view',
                        component: () => import('../pages/applicationForms/form/View')
                    }),

                    new Route({
                        path: 'edit', label: 'pages.applicationForm.breadcrumb.edit', name: 'applicationForms.edit',
                        component: () => import('../pages/applicationForms/form/Edit')
                    }),
                ]
            })
        ]
    }),

    // Logout
    new Route({
        path: '/logout', label: 'html.buttons.logout', name: 'logout', hidden: true,
        component: () => import('../pages/logout/Frame')
    })
];
